import * as lazy from "./lazyLoadFiles";
import { ErrorBoundary } from "../components/error-boundary";
import { roles } from "../constants/roles";
import { ProtectedRoutes } from "../components/protected-routes";

export const routesData = [
  {
    path: "*",
    element: <>page Not found</>,
    role: [roles.admin, roles.student],
  },
  {
    path: "/",
    element: (
      <ErrorBoundary fallback="something went worng...">
        <lazy.LazyLogin />
      </ErrorBoundary>
    ),
    role: [roles.admin, roles.student],
  },
  {
    path: "/sign-up",
    element: (
      <ErrorBoundary fallback="something went worng...">
        <lazy.LazySignUp />
      </ErrorBoundary>
    ),
    role: [roles.admin, roles.student],
  },
  {
    path: "/email-verification",
    element: (
      <ErrorBoundary fallback="something went worng...">
        <lazy.LazyEmailVerification />
      </ErrorBoundary>
    ),
    role: [roles.admin, roles.student],
  },
  {
    path: "/phone-verification",
    element: (
      <ErrorBoundary fallback="something went worng...">
        <lazy.LazyPhoneVerification />
      </ErrorBoundary>
    ),
    role: [roles.admin, roles.student],
  },
  {
    path: "/reset-password",
    element: (
      <ErrorBoundary fallback="something went worng...">
        <lazy.LazyResetPassword />
      </ErrorBoundary>
    ),
    role: [roles.admin, roles.student],
  },
  {
    path: "/password-sent",
    element: (
      <ErrorBoundary fallback="something went worng...">
        <lazy.LazyPasswordSent />
      </ErrorBoundary>
    ),
    role: [roles.admin, roles.student],
  },
  {
    path: "/set-password",
    element: (
      <ErrorBoundary fallback="something went worng...">
        <lazy.LazySetPassword />
      </ErrorBoundary>
    ),
    role: [roles.admin, roles.student],
  },
  {
    path: "/password-changed",
    element: (
      <ErrorBoundary fallback="something went worng...">
        <lazy.LazyPasswordChanged />
      </ErrorBoundary>
    ),
    role: [roles.admin, roles.student],
  },
  {
    path: "/payment-error",
    element: (
      <ErrorBoundary fallback="something went worng...">
        <lazy.LazySuccessError />
      </ErrorBoundary>
    ),
    role: [roles.admin, roles.student],
  },
  {
    path: "/payment-success",
    element: (
      <ErrorBoundary fallback="something went worng...">
        <lazy.LazySuccessPayment />
      </ErrorBoundary>
    ),
    role: [roles.admin, roles.student],
  },
  {
    path: "/*",
    element: (
      <ProtectedRoutes>
        <ErrorBoundary fallback="something went worng...">
          <lazy.LazyMainLayout />
        </ErrorBoundary>
      </ProtectedRoutes>
    ),
    role: [roles.admin, roles.student],
    children: [
      {
        path: "chat",
        element: <lazy.LazyChats />,
        role: [roles.admin, roles.student],
      },
      // {
      //   path: "chat/:id",
      //   element: <lazy.LazyChats />,
      //   role: [roles.admin, roles.student],
      // },
      {
        path: "course",
        element: <lazy.LazyChaptersOutlet />,
        role: [roles.student],
        children: [
          {
            path: "",
            element: <lazy.LazyChapters />,
            role: [roles.student],
          },
          {
            path: ":lessonid",
            element: <lazy.LazyLessons />,
            role: [roles.student],
          },
        ],
      },

      {
        path: "profile",
        element: <lazy.LazyProfile />,
        role: [roles.student, roles.admin],
        children: [
          {
            path: "",
            element: <lazy.LazyProfileLayout />,
            role: [roles.student, roles.admin],
          },
          {
            path: "change-password",
            element: <lazy.LazyProfileChangePassword />,
            role: [roles.student, roles.admin],
          },
          {
            path: "change-plan",
            element: <lazy.LazyProfileChangePlan />,
            role: [roles.student, roles.admin],
          },
        ],
      },
      {
        path: "subscription",
        element: <lazy.LazySubscriptions />,
        role: [roles.admin],
      },
      {
        path: "testimonial",
        element: <lazy.LazyTestimonials />,
        role: [roles.admin],
      },
      {
        path: "user-management",
        element: <lazy.LazyUserManagement />,
        role: [roles.admin],
      },
      {
        path: "reminder",
        element: <lazy.LazyReminder />,
        role: [roles.admin],
      },
      {
        path: "legals",
        element: <lazy.LazyLegals />,
        role: [roles.admin],
        children: [
          {
            path: "",
            element: <lazy.LazyLegals />,
            role: [roles.admin],
          },
          {
            path: "terms-and-conditions",
            element: <lazy.LazyLegals />,
            role: [roles.admin],
          },
          {
            path: "course-policy",
            element: <lazy.LazyLegals />,
            role: [roles.admin],
          },
        ],
      },
    ],
  },
];
