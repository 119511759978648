import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

const initialState = {
  userDetail: null,
  profileDetail: null,
  showNotification: false,
  notificationCount: 0,
};

export const useAuth = create(
  persist(
    () => ({
      ...initialState,
    }),
    {
      name: "userDetails",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
